<template>
  <div class="home-types">
    <div class="line mx-auto register-primary mb-4"></div>
    <div class="text-center">
      <p class="font-48 font-800 darktext--text mb-0">
        {{ $t("userTypes.joinOurNetwork") }}
      </p>
    </div>

    <div
      class="home-types__cover white radius-15 text-center py-11"
      v-for="(type, i) in types"
      :key="i"
    >
      <!-- join us  -->
      <div class="d-flex align-center justify-center">
        <div class="d-flex align-center">
          <img :src="type.icon" alt="" />
          <div class="text-left mx-3">
            <span class="font-21 font-900 greytext--text d-block">{{
              type.title
            }}</span>
            <span class="font-16 lightgreytext--text d-block">{{
              type.subTitle
            }}</span>
          </div>
        </div>
      </div>
      <span class="font-32 font-800 bord--text d-block mt-10">{{
        type.name
      }}</span>
      <p class="font-18 px-5 mt-3">{{ type.desc }}</p>

      <!-- btn -->
      <div
        class="
          home-types__btn
          mx-auto
          py-3
          radius-10
          d-flex
          align-center
          justify-center
          cursor-pointer
          mt-6
          hover-btn
        "
        style="width: 220px"
        @click="$router.push(type.route)"
      >
        <span class="font-700 font-18 register-primary--text">{{
          $t("userTypes.signup")
        }}</span>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  data: (vm) => ({
    types: [
      {
        title: vm.$t("userTypes.joinUs"),
        icon: require("@/assets/images/icons/hospital.svg"),
        subTitle: vm.$t("userTypes.asHospital"),
        name: vm.$t("userTypes.institution"),
        route: "/register/signup-clinic",
        desc: vm.$t("userTypes.asHospitalDesc"),
      },
    ],
  }),
};
</script>
    
    <style lang="scss">
.home-types {
  &__cover {
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 20px;
    p {
      color: #646464;
    }
  }
  &__btn {
    border: 1px solid $register-primary;
    background: white;
  }
}
</style>
    