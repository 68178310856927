<template>
  <div class="">
    <img :class="data.side =='right' ? 'right-line': 'left-line'" :src="data.sideImg" alt="" />
    <div class="custom-step pa-3 radius-30 custom-step--white" >

    <div class="px-1 pt-1">
      <span class="d-block font-24 font-700 register-primary--text">{{
        data.title
      }}</span>
      <p class="font-22 darktext--text mt-4">{{ data.desc }}</p>
    </div>
    <img class="radius-30 object-cover" height="300px" width="100%" :src="data.img" alt="" />
    </div>
  </div>
</template>

<script>
export default {
    props:{
        data:{
            type: Object,
            default: () => {}
        },
        isWhite:{
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss">
.custom-step{
    position: relative;
    z-index: 9;
    &--white{
      background: white;
    box-shadow: 0px 56px 80px 0px #ADC7EE66 !important;
    }
    &--default{
      background: $bordercolor;
      img{
    box-shadow: 0px 11px 13px -2px #adc7ee70 !important;

      }
    }
}
.right-line{
    position: relative;
    left: -20%
}
.left-line{
        position: relative;
    right: -50%
}
</style>
