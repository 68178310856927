<template>
  <div class="recommend-section">
    <div class="line mx-auto register-primary mb-4 mt-14"></div>
    <div class="text-center mb-11">
      <p class="font-48 font-800 darktext--text mb-0">
        {{ $t("homepage.clientRecommendations") }}
      </p>
    </div>
    <div class="shadow-bg" v-if="data.length > 2"></div>

    <slick-carousel class="px-6" v-bind="settings">
      <div class="px-3" v-for="(item, i) in data" :key="i">
        <div
          class="recommend-section__slide white radius-15 py-4 px-8 text-center"
        >
          <img
            class="object-cover mx-auto"
            :src="item.image"
            alt=""
          />
          <span class="d-block font-16 font-800 black--text mt-4"
            >{{item.name}}</span
          >
          <span class="d-block font-14 lightbord--text mt-1"
            >{{item.specialty}}</span
          >
          <p class="font-14 mt-3">
            {{item.message}}
          </p>
        </div>
      </div>
      <template #prevArrow>
        <div class="custom-arrow">
          <img
            width="37px"
            :src="require('@/assets/images/icons/arrow-left.svg')"
          />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-arrow">
          <img
            width="37px"
            :src="require('@/assets/images/icons/arrow-right.svg')"
          />
        </div>
      </template>
    </slick-carousel>
  </div>
</template>

<script>
export default {
  data: () => ({
    settings: {
      dots: true,
      focusOnSelect: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      touchThreshold: 5,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
      ],
    },
    items: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    data: [],
  }),

  methods:{
    async getTestimonials(){
      let {data} = await this.$axios.get('testimonials')
      console.log("Hello World");
      console.log(data.data);
      if(data){
        this.data = data.data
      }
    },
  },
  async created(){
    await this.getTestimonials()
  }
};
</script>



<style lang="scss">
.recommend-section {
  margin-bottom: 15%;
  position: relative;
  .shadow-bg {
    position: absolute;
    bottom: 0px;
    left: 50%;
    top: 59%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 56px 80px 0px #adc7eed1;
    width: 250px;
    height: 175px;
  }
  &__slide {
    height: auto;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    P {
      color: #636363;
      line-height: 28px;
    }
  }
}
.slick-dots {
  padding-left: 0px !important;
  bottom: -60px;
  li {
    position: relative;
    width: 17px;
    button {
      width: 11.06px;
      height: 5.53px;
      background: #ffffff;
      border-radius: 34.4096px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.3s;
      &::before {
        display: none;
      }
    }
  }

  li.slick-active {
    width: 95px;
    button {
      background: $register-primary;
      width: 90px;
    }
  }
}
@media (max-width: 900px) {
  .slick-dots {
    display: none !important;
  }
  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 0px;
  }
}
.slick-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  &::before {
    display: none !important;
  }
}
</style>
